import React, { useEffect } from 'react';
import { PageProps } from 'gatsby';
import { navigate } from 'gatsby-link';
import { Helmet } from 'react-helmet';
import { useTreatments } from '@splitsoftware/splitio-react';

import { Split, Treatment } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';
import { SiteDisclaimer } from 'src/components/Disclaimers';
import { useTracking } from 'src/components/TrackingContext';
import { MainFooter } from 'src/features/footer';
import { useAutoLogin } from 'src/hooks/useAutoLogin';

const meta = {
  title: 'Discover and Sign up for Hagerty Drivers Club Events and Experiences | Hagerty',
  description:
    'Hagerty Drivers Club members have exclusive access to hands-on, behind-the-wheel events and experiences including scenic driving tours, rides & drives, member appreciation events, seminars and more.',
};

const Events: React.FC<PageProps> = () => {
  useAutoLogin();
  const splitIsReady = isReady();
  const treatments = useTreatments([Split.FeatureEventsPage]);
  const hasEventsPage = treatments[Split.FeatureEventsPage].treatment === Treatment.On;

  useTracking('events_and_experiences_view');

  useEffect(() => {
    if (splitIsReady) {
      if (hasEventsPage) {
        const script = document.createElement('script');

        script.src = process.env.MSR_WIDGET_SRC;
        script.async = true;
        script.setAttribute('data-api-key', process.env.MSR_API_KEY);
        script.setAttribute('data-widget-id', '1536f52e-1cc2-41eb-944a-4c21576257ae');
        script.setAttribute('data-mount', 'calendar-widget');

        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      } else {
        navigate('/');
      }
    }
  }, [splitIsReady]);

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="robots" content="all" />
      </Helmet>
      <div className="container">
        <div className="inset-m" />
        <h1 className="text-display_2">Events and Experiences</h1>
        <div className="min-h-screen">
          <div id="calendar-widget"></div>
        </div>
      </div>
      <div className="inset-l" />
      <footer className="container">
        <SiteDisclaimer />
      </footer>
      <MainFooter />
    </>
  );
};

export default Events;
